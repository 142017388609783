/* Sticky footer styles
-------------------------------------------------- */

footer {
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 3em;
  line-height: 3em;
  background-color: #fafafa;
  text-align: center;
  font-size: 0.85rem;
  color: #000000;
  position: fixed;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  z-index: 2;
}

footer a {
  color: #000000;
}
footer a:active,
footer a:hover {
  text-decoration: underline;
  color: #000000;
}
