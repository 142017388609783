.faq .images,
.usage_guide .images {
  text-align: center;
  width: 85%;
}
.faq legend,
.usage_guide legend {
  font-size: 16px;
}
.faq inner,
.usage_guide .inner {
  margin-left: 60px;
}
.faq p,
.faq b,
.usage_guide p,
.usage_guide b {
  font-size: 16px;
}

.faq ol,
.usage_guide ol.answerlist {
  list-style-type: lower-alpha;
}
.faq h1,
.usage_guide h1 {
  font-size: 36px;
  border-bottom: 1px solid #0275d8;
  margin-bottom: 0.3em;
}
.faq h3,
.usage_guide h3 {
  border-bottom: 1px solid #0275d8;
  padding: 0px;
  padding-bottom: 5px;
  margin-top: 1rem;
}
.faq legend,
.usage_guide legend {
  margin-left: -2rem;
}
.faq ul.sublist,
.usage_guide ul.sublist {
  list-style-type: lower-alpha;
  font-size: 16px;
}
.faq li,
.usage_guide li {
  font-size: 16px;
}

.usage_guide .sublist li {
  font-weight: bold;
}
.usage_guide ol.roman,
.usage_guide ul.sublist ol.roman li {
  list-style-type: lower-roman;
  font-weight: normal;
}
li p.answer {
  font-weight: normal;
}

a.back {
  font-weight: bold;
}

.answerlist li {
  font-size: 16px;
}
