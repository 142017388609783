div > table.sr {
}
.searchResults {
  margin-top: 1rem;
}
.searchResults p {
  margin-top: 0;
  margin-bottom: 0;
}
table.sr {
  border: 1px solid black;
  padding: 15px;
  padding-top: 5px;
  width: 47.5%;
  max-height: 24em;
  overflow-y: scroll;
  display: block;
  min-width: 47.5%;
}

.sr th {
  text-align: left;
  border-bottom: 1px solid #c0c0c0;
  font-weight: 400;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  font-family: 'BrandonText' !important;
  height: inherit !important;
}
.sr tr td {
  padding-left: 10px;
}

.sr tr:hover {
  background-color: #c0c0c0;
}
.sr thead tr:hover {
  background-color: #ffffff;
}
.sr tr:hover a {
  color: #ffffff;
  font-weight: 600;
}
.sr a.linkblue,
.sr tr:hover a.linkblue {
  color: #0a66b7;
}

.sr tbody {
  border-top: none;
}
.submitSearch {
  display: inline-block;
  float: right;
  width: 157px;
  margin-left: auto;
}

.working {
  font-size: 1.4em;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

.mainReportForm .searchBox .invalidInput {
  float: left;
  padding-left: 3px;
  margin-top: 0px;
}
.searchBox {
  padding-right: 18px;
}
.btnSubmitSearch {
  min-width: 90%;
}
.departmentBox {
  padding: 10px;
  border-radius: 4px;
  border-collapse: collapse;
  border: #ccc 1px solid;
  width: 100%;
  margin-bottom: 0.5em;
  background-color: transparent;

  box-sizing: border-box;
  margin-bottom: 10px;
  background: #fff;
  height: 36px;
}
.rw-state-readonly .rw-input {
  opacity: 0.3;
}
.departmentBox:focus {
  outline-width: 2px;
  outline-color: #007bff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  outline-radius: 6px;
  border: none;
  border-collapse: separate;
}

.btn.btnReset {
  border: 1px solid rgba(0, 0, 0, 0.15);
}
