ul.reportList {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.reportList > li {
  margin-top: 2em;
  border: 3px solid #c0c0c0;
  padding: 15px;
  position: relative;
  background-color: #ffffff;
}

.plusicon {
  float: left;
  margin-right: 1em;
  padding-top: 6px;
  cursor: pointer;
}
.regionselect {
  height: 240px;
  width: 100%;
  min-width: 100%;
  margin-bottom: 10px;
  padding: 12px;
}
.regionselect option {
  padding: 4px;
}
.regionlabel {
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 10px;
}
.reportexpanded .title {
  font-weight: bold;
}

.vpnlabel {
  margin-right: 15px;
}
.vpnbox {
  margin-bottom: 10px;
}
.vpnlist {
  border: 1px solid #cccccc;
  right: 10px;
  height: 150px;
  min-width: 100%;
  width: 100%;
  overflow-y: scroll;
  padding-left: 8px;
  text-wrap: avoid;
  white-space: nowrap;
  margin-bottom: 20px;
}
.vpnlist.widevpn {
  overflow-x: scroll;
}
.vpnlist ul {
  list-style-type: none;
}
.vpnlist li {
  list-style: none;
}
.vpnlist li .fa-trash {
  color: red;
  margin-right: 10px;
  cursor: pointer;
}
.vpnplus {
  color: '#0275d8';
  cursor: pointer;
}
div.buttoncell {
  padding-right: 15px;
  padding-left: 15px;
}
ul.reportList .btn,
.btn-primary,
.btn-primary:focus {
  white-space: normal;
  border-radius: 0px;
  background-color: #393939;
  border-color: #ffffff;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 100%;
  font-size: inherit;
}

.generateBtn {
}

.required {
  color: red;
}

.mainReportForm .form {
  padding: 0px;
}

p.alert {
  margin-top: 20px;
}
.reportsContainer {
  padding-bottom: 40px;
  margin-left: 90px;
}
@media (min-width: 100px) {
  .appContent {
    margin: 10px;
    margin-top: -15px;
  }
  .btn {
    font-size: 0.714em;
  }
  ul.reportList {
    font-size: 10px;
  }
}

@media (min-width: 567px) {
  .appContent {
    margin: 10px;
  }
  .btn {
    font-size: 0.833em;
  }
  ul.reportList {
    font-size: 14px;
  }
}
@media (min-width: 1200px) {
  .appContent {
    margin: 10px;
  }
  .btn {
    font-size: 1em;
  }
  ul.reportList {
    font-size: 16px;
  }
}
