.result thead {
  font-size: 0.64em;
  white-space: nowrap;
}
form label {
  font-weight: bold;
}
td.center {
  text-align: center;
}
thead th {
  text-align: center;
}
.result tbody {
  /* font-size: 0.75em; */
}
td {
  padding: 5px;
  vertical-align: top;
}
th.orderFromBg {
  text-align: left;
  background-color: #ddebf6;
  font-size: 18px;
  padding-left: 8px;
}
th.payToBg {
  text-align: left;
  background-color: #fbe4d7;
  font-size: 18px;
  padding-left: 8px;
}
th.prtnrBg {
  background-color: #b2b19c;
  font-size: 18px;
  text-align: left;
  padding-left: 8px;
}
th.parentPayToBg {
  background-color: #afdbb9;
  font-size: 18px;
  text-align: left;
  padding-left: 8px;
}
th.labelBg {
  background-color: #b25a29;
  color: #fff;
  font-size: 18px;
  text-align: left;
  padding-left: 8px;
}
th.brandBg {
  background-color: #9ddbda;
  font-size: 18px;
  text-align: left;
  padding-left: 8px;
}
.tab-ofv {
  background-color: #ddebf6;
}
.tab-payTo {
  background-color: #fbe4d7;
}
.tab-prtnr {
  background-color: 'yellow';
}

table.result {
  width: 100%;
}
ul.fscheck {
  list-style-type: none;
}
ul.fscheck li {
  display: inline-block;
  margin-right: 15px;
  text-align: left;
}
.welcome-heres-how {
  font-family: Helvetica;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}
.welcome-content {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #39393a;
}
.filterSearchType {
  font-family: Helvetica;
  font-size: 20px;
  font-weight: bold;
  color: #878787;
}
.filterSearchName {
  font-family: Helvetica;
  font-size: 20px;
  font-weight: bold;
  color: #000;
}
.section-title {
  width: 97px;
  height: 17px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  text-align: left;
}

thead th {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;

  padding: 4px;
}

tbody {
  border-top: 1px solid #b2b19c;
}

p.emptyResults {
  color: #fb0007;
}
.react-tabs [role='tab'][aria-selected='true'] {
  border-radius: 0 !important;
}
.invalidInput {
  font-family: Helvetica;
  font-size: 11px;
  color: #d0021b;
  float: right;
  display: inline-block;
  margin-top: 8px;
  font-weight: 600;
  padding-top: 8px;
  margin-right: 15px;
}
.ofocus {
  opacity: 0.45;
}
.ofocus input {
  color: #cccccc;
  border: 1px dotted #cccccc;
}
.ifocus {
  /* background-color: rgb(165, 165, 165); */
  /* padding: 5px; */
  /* border: 1px solid gray; */
  /* color: #fff; */
  opacity: 1;
}
.ifocus input {
  border: 1px solid #cccccc;
}
.highlight:hover {
  background-color: #cccccc;
}

.row.assocInactive a,
.row.assocInactive span {
  text-decoration: line-through;
  font-style: italic;
}
.highlight:hover {
  background-color: #cccccc;
}

.row.assocInactive a,
.row.assocInactive span {
  text-decoration: line-through;
  font-style: italic;
}
.react-grid-Grid input {
  font-size: 10px;
  padding: 5px;
}
.flagsblock {
  text-align: 'left';
  width: '60px';
  margin: 'auto';
}
.searchBox,
.searchOr {
  display: inline-block;
  padding-right: 2em;
}
