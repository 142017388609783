@font-face {
  font-family: 'BrandonText';
  src: url(../../static/media/BrandonTextRegular.f5ca077b.woff) format('woff');
}

body {
  font-family: 'BrandonText' !important;
  -webkit-font-smoothing: antialiased;
}

#profile-menu a,
#profile-menu a:active {
  color: black;
  text-decoration: none;
}

/* Sticky footer styles
-------------------------------------------------- */

footer {
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 3em;
  line-height: 3em;
  background-color: #fafafa;
  text-align: center;
  font-size: 0.85rem;
  color: #000000;
  position: fixed;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  z-index: 2;
}

footer a {
  color: #000000;
}
footer a:active,
footer a:hover {
  text-decoration: underline;
  color: #000000;
}

.faq .images,
.usage_guide .images {
  text-align: center;
  width: 85%;
}
.faq legend,
.usage_guide legend {
  font-size: 16px;
}
.faq inner,
.usage_guide .inner {
  margin-left: 60px;
}
.faq p,
.faq b,
.usage_guide p,
.usage_guide b {
  font-size: 16px;
}

.faq ol,
.usage_guide ol.answerlist {
  list-style-type: lower-alpha;
}
.faq h1,
.usage_guide h1 {
  font-size: 36px;
  border-bottom: 1px solid #0275d8;
  margin-bottom: 0.3em;
}
.faq h3,
.usage_guide h3 {
  border-bottom: 1px solid #0275d8;
  padding: 0px;
  padding-bottom: 5px;
  margin-top: 1rem;
}
.faq legend,
.usage_guide legend {
  margin-left: -2rem;
}
.faq ul.sublist,
.usage_guide ul.sublist {
  list-style-type: lower-alpha;
  font-size: 16px;
}
.faq li,
.usage_guide li {
  font-size: 16px;
}

.usage_guide .sublist li {
  font-weight: bold;
}
.usage_guide ol.roman,
.usage_guide ul.sublist ol.roman li {
  list-style-type: lower-roman;
  font-weight: normal;
}
li p.answer {
  font-weight: normal;
}

a.back {
  font-weight: bold;
}

.answerlist li {
  font-size: 16px;
}

.home-humility {
  text-align: center;
}

.home-fa-twitter {
  margin-left: 2em;
}
.landing {
  width: 90%;
  margin: auto;
}
ul.landing {
  list-style-type: none;
  font-size: 2vh;
  max-width: 100%;
}
.landing a {
  text-decoration: none;
  color: black;
}
.landing a:hover {
  text-decoration: none;
  color: #c0c0c0;
}
.landing p {
}
.landing .welcome {
  margin-top: 0px;
}
.landing li span.adminLabel {
  border: 2px solid #d9edf7;
  color: #025aa5;
  padding: 3px;
  paddingleft: 3px;
  paddingright: 3px;
  margin-right: 10px;
}
.landing li span.vendorNumber {
  color: rgba(0, 0, 0, 0.54);
  padding-right: 0px;
}

.images img {
  margin-right: 10px;
}

.fa {
  cursor: pointer;
}
.contentEditor {
  border: 1px solid #eee;
  padding-top: 5px;
  margin-right: 18px;
}
.contentEditor ul {
  padding-left: 10px;
}
.contentEditor li {
  border-bottom: none;
  margin-left: -10px;
  list-style: none;
  display: inline-block;
  width: 100%;
}

.btn {
  cursor: pointer;
}
.motdEditor {
  min-height: 300px;
}
.motd_edit.saving .editor-class {
  opacity: 0.5;
}
.contentEditor li {
  padding-left: 10px;
}
.contentEditor li a {
  display: inline-block;
  width: 80%;
  padding-left: 10px;
}
.contentEditor li > a:hover {
  background-color: #c0c0c0;
  color: #ffffff;
}
.landing .contentEditor li a:hover {
  font-weight: 600 !important;
}

.rw-list-option {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

ul.reportList {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.reportList > li {
  margin-top: 2em;
  border: 3px solid #c0c0c0;
  padding: 15px;
  position: relative;
  background-color: #ffffff;
}

.plusicon {
  float: left;
  margin-right: 1em;
  padding-top: 6px;
  cursor: pointer;
}
.regionselect {
  height: 240px;
  width: 100%;
  min-width: 100%;
  margin-bottom: 10px;
  padding: 12px;
}
.regionselect option {
  padding: 4px;
}
.regionlabel {
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 10px;
}
.reportexpanded .title {
  font-weight: bold;
}

.vpnlabel {
  margin-right: 15px;
}
.vpnbox {
  margin-bottom: 10px;
}
.vpnlist {
  border: 1px solid #cccccc;
  right: 10px;
  height: 150px;
  min-width: 100%;
  width: 100%;
  overflow-y: scroll;
  padding-left: 8px;
  text-wrap: avoid;
  white-space: nowrap;
  margin-bottom: 20px;
}
.vpnlist.widevpn {
  overflow-x: scroll;
}
.vpnlist ul {
  list-style-type: none;
}
.vpnlist li {
  list-style: none;
}
.vpnlist li .fa-trash {
  color: red;
  margin-right: 10px;
  cursor: pointer;
}
.vpnplus {
  color: '#0275d8';
  cursor: pointer;
}
div.buttoncell {
  padding-right: 15px;
  padding-left: 15px;
}
ul.reportList .btn,
.btn-primary,
.btn-primary:focus {
  white-space: normal;
  border-radius: 0px;
  background-color: #393939;
  border-color: #ffffff;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 100%;
  font-size: inherit;
}

.generateBtn {
}

.required {
  color: red;
}

.mainReportForm .form {
  padding: 0px;
}

p.alert {
  margin-top: 20px;
}
.reportsContainer {
  padding-bottom: 40px;
  margin-left: 90px;
}
@media (min-width: 100px) {
  .appContent {
    margin: 10px;
    margin-top: -15px;
  }
  .btn {
    font-size: 0.714em;
  }
  ul.reportList {
    font-size: 10px;
  }
}

@media (min-width: 567px) {
  .appContent {
    margin: 10px;
  }
  .btn {
    font-size: 0.833em;
  }
  ul.reportList {
    font-size: 14px;
  }
}
@media (min-width: 1200px) {
  .appContent {
    margin: 10px;
  }
  .btn {
    font-size: 1em;
  }
  ul.reportList {
    font-size: 16px;
  }
}

.result thead {
  font-size: 0.64em;
  white-space: nowrap;
}
form label {
  font-weight: bold;
}
td.center {
  text-align: center;
}
thead th {
  text-align: center;
}
.result tbody {
  /* font-size: 0.75em; */
}
td {
  padding: 5px;
  vertical-align: top;
}
th.orderFromBg {
  text-align: left;
  background-color: #ddebf6;
  font-size: 18px;
  padding-left: 8px;
}
th.payToBg {
  text-align: left;
  background-color: #fbe4d7;
  font-size: 18px;
  padding-left: 8px;
}
th.prtnrBg {
  background-color: #b2b19c;
  font-size: 18px;
  text-align: left;
  padding-left: 8px;
}
th.parentPayToBg {
  background-color: #afdbb9;
  font-size: 18px;
  text-align: left;
  padding-left: 8px;
}
th.labelBg {
  background-color: #b25a29;
  color: #fff;
  font-size: 18px;
  text-align: left;
  padding-left: 8px;
}
th.brandBg {
  background-color: #9ddbda;
  font-size: 18px;
  text-align: left;
  padding-left: 8px;
}
.tab-ofv {
  background-color: #ddebf6;
}
.tab-payTo {
  background-color: #fbe4d7;
}
.tab-prtnr {
  background-color: 'yellow';
}

table.result {
  width: 100%;
}
ul.fscheck {
  list-style-type: none;
}
ul.fscheck li {
  display: inline-block;
  margin-right: 15px;
  text-align: left;
}
.welcome-heres-how {
  font-family: Helvetica;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}
.welcome-content {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #39393a;
}
.filterSearchType {
  font-family: Helvetica;
  font-size: 20px;
  font-weight: bold;
  color: #878787;
}
.filterSearchName {
  font-family: Helvetica;
  font-size: 20px;
  font-weight: bold;
  color: #000;
}
.section-title {
  width: 97px;
  height: 17px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  text-align: left;
}

thead th {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;

  padding: 4px;
}

tbody {
  border-top: 1px solid #b2b19c;
}

p.emptyResults {
  color: #fb0007;
}
.react-tabs [role='tab'][aria-selected='true'] {
  border-radius: 0 !important;
}
.invalidInput {
  font-family: Helvetica;
  font-size: 11px;
  color: #d0021b;
  float: right;
  display: inline-block;
  margin-top: 8px;
  font-weight: 600;
  padding-top: 8px;
  margin-right: 15px;
}
.ofocus {
  opacity: 0.45;
}
.ofocus input {
  color: #cccccc;
  border: 1px dotted #cccccc;
}
.ifocus {
  /* background-color: rgb(165, 165, 165); */
  /* padding: 5px; */
  /* border: 1px solid gray; */
  /* color: #fff; */
  opacity: 1;
}
.ifocus input {
  border: 1px solid #cccccc;
}
.highlight:hover {
  background-color: #cccccc;
}

.row.assocInactive a,
.row.assocInactive span {
  text-decoration: line-through;
  font-style: italic;
}
.highlight:hover {
  background-color: #cccccc;
}

.row.assocInactive a,
.row.assocInactive span {
  text-decoration: line-through;
  font-style: italic;
}
.react-grid-Grid input {
  font-size: 10px;
  padding: 5px;
}
.flagsblock {
  text-align: 'left';
  width: '60px';
  margin: 'auto';
}
.searchBox,
.searchOr {
  display: inline-block;
  padding-right: 2em;
}

div > table.sr {
}
.searchResults {
  margin-top: 1rem;
}
.searchResults p {
  margin-top: 0;
  margin-bottom: 0;
}
table.sr {
  border: 1px solid black;
  padding: 15px;
  padding-top: 5px;
  width: 47.5%;
  max-height: 24em;
  overflow-y: scroll;
  display: block;
  min-width: 47.5%;
}

.sr th {
  text-align: left;
  border-bottom: 1px solid #c0c0c0;
  font-weight: 400;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  font-family: 'BrandonText' !important;
  height: inherit !important;
}
.sr tr td {
  padding-left: 10px;
}

.sr tr:hover {
  background-color: #c0c0c0;
}
.sr thead tr:hover {
  background-color: #ffffff;
}
.sr tr:hover a {
  color: #ffffff;
  font-weight: 600;
}
.sr a.linkblue,
.sr tr:hover a.linkblue {
  color: #0a66b7;
}

.sr tbody {
  border-top: none;
}
.submitSearch {
  display: inline-block;
  float: right;
  width: 157px;
  margin-left: auto;
}

.working {
  font-size: 1.4em;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

.mainReportForm .searchBox .invalidInput {
  float: left;
  padding-left: 3px;
  margin-top: 0px;
}
.searchBox {
  padding-right: 18px;
}
.btnSubmitSearch {
  min-width: 90%;
}
.departmentBox {
  padding: 10px;
  border-radius: 4px;
  border-collapse: collapse;
  border: #ccc 1px solid;
  width: 100%;
  margin-bottom: 0.5em;
  background-color: transparent;

  box-sizing: border-box;
  margin-bottom: 10px;
  background: #fff;
  height: 36px;
}
.rw-state-readonly .rw-input {
  opacity: 0.3;
}
.departmentBox:focus {
  outline-width: 2px;
  outline-color: #007bff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  outline-radius: 6px;
  border: none;
  border-collapse: separate;
}

.btn.btnReset {
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.appContent {
  margin: 0;
  margin-top: 64px;
}
body,
h1,
h2,
h3,
h4 {
  color: #393939;
}
.frontImg {
  width: 100%;
}

.contentpanel {
  background-color: #fafafa;
  justify-content: center;
  margin-right: -15px;
  border-left: 1px solid #cccccc;
  margin-top: 6px;
  flex: 1 1 auto;
  display: flex;
  padding-bottom: 200px;
}
.container {
  margin-bottom: 64px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
}
.contentpanel .container {
  margin-top: 64px;
}

.leftpanel {
  float: left;
  height: 100%;
  flex: 1 1 auto;
  display: flex;
}
ul.links {
  padding: 0;
  margin: 0;
  list-style-type: none;

  color: rgba(0, 0, 0, 0.87);
  padding-top: 1em;
  cursor: pointer;
}

.leftnav li {
  font-size: 1rem;
  padding: 10px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.leftnav li a {
  color: rgba(0, 0, 0, 0.87);
  display: block;
  width: 100%;
  height: 100%;
}
.leftnav li a,
.leftnav li a:active,
.leftnav li a:visited {
  text-decoration: none;
}

.leftnav ul li:hover {
  background-color: #f7f7f7;
  color: rgba(0, 0, 0, 0.9);
}
.leftnav .fa {
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.54);
}

.leftnav hr {
  background-color: rgba(0, 0, 0, 0.12);
  margin: 0;
}

a.navbar-brand {
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: initial;
}
.navbar-brand img {
  width: 190px;
  height: 23.91px;
}

.apphead {
  font-weight: 400;
  font-family: BrandonText;
  line-height: 1.16667em;
  display: inline-block;
  width: 100%;
  height: 56px;
}

.lefthead {
  width: 243px;
  float: left;

  padding-bottom: 15px;
  border-bottom: 1px solid #cccccc;
  height: 63px;
  padding: 20px;
}
.righthead {
  background-color: #607d8b;
  color: #ffffff;
  margin-left: 243px;
  padding: 24px;
  height: 63px;
  font-size: 1.3125rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.contentpanel .container {
  padding-top: 20px;
}

.motd {
  border: 1px solid #607d8b;
  padding: 34px;
  background-color: #ffffff;
}
.motd a {
  color: #0275d8;
}

.motd a:hover,
.motd a:active {
  color: #0275d8;
  text-decoration: underline;
}
.userNotification {
  margin-left: 5em;
  background: white;
  color: #607d8b;
  padding: 10px;
  line-height: 1.5;
  font-size: 1rem;
}
.userNotification .fa {
  color: red;
  padding-right: 1em;
  font-size: 1.3rem;
}

.appwhole {
  display: flex;
}
.appmain {
  margin-top: 64px;
  padding-top: 25px;
}

div.faq,
div.contactus {
  margin-left: 75px;
}

div.appheader {
  display: flex;
}

div.mt20 {
  margin-top: 20px;
}

a,
a:visited,
a:hover,
a:active,
a:focus,
button:hover,
button:focus,
button:active {
  text-decoration: none !important;
  outline: none !important;
}

