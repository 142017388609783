.home-humility {
  text-align: center;
}

.home-fa-twitter {
  margin-left: 2em;
}
.landing {
  width: 90%;
  margin: auto;
}
ul.landing {
  list-style-type: none;
  font-size: 2vh;
  max-width: 100%;
}
.landing a {
  text-decoration: none;
  color: black;
}
.landing a:hover {
  text-decoration: none;
  color: #c0c0c0;
}
.landing p {
}
.landing .welcome {
  margin-top: 0px;
}
.landing li span.adminLabel {
  border: 2px solid #d9edf7;
  color: #025aa5;
  padding: 3px;
  paddingleft: 3px;
  paddingright: 3px;
  margin-right: 10px;
}
.landing li span.vendorNumber {
  color: rgba(0, 0, 0, 0.54);
  padding-right: 0px;
}

.images img {
  margin-right: 10px;
}

.fa {
  cursor: pointer;
}
.contentEditor {
  border: 1px solid #eee;
  padding-top: 5px;
  margin-right: 18px;
}
.contentEditor ul {
  padding-left: 10px;
}
.contentEditor li {
  border-bottom: none;
  margin-left: -10px;
  list-style: none;
  display: inline-block;
  width: 100%;
}

.btn {
  cursor: pointer;
}
.motdEditor {
  min-height: 300px;
}
.motd_edit.saving .editor-class {
  opacity: 0.5;
}
.contentEditor li {
  padding-left: 10px;
}
.contentEditor li a {
  display: inline-block;
  width: 80%;
  padding-left: 10px;
}
.contentEditor li > a:hover {
  background-color: #c0c0c0;
  color: #ffffff;
}
.landing .contentEditor li a:hover {
  font-weight: 600 !important;
}

.rw-list-option {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
