@font-face {
  font-family: 'BrandonText';
  src: url('./BrandonTextRegular.woff') format('woff');
}

body {
  font-family: 'BrandonText' !important;
  -webkit-font-smoothing: antialiased;
}

#profile-menu a,
#profile-menu a:active {
  color: black;
  text-decoration: none;
}
